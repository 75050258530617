"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PublicApi = exports.PublicApiFactory = exports.PublicApiFp = exports.PublicApiAxiosParamCreator = exports.AuthenticationRequiredApi = exports.AuthenticationRequiredApiFactory = exports.AuthenticationRequiredApiFp = exports.AuthenticationRequiredApiAxiosParamCreator = exports.Currency = void 0;
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var common_1 = require("./common");
// @ts-ignore
var base_1 = require("./base");
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
exports.Currency = {
    Cad: 'CAD',
    Usd: 'USD'
};
/**
 * AuthenticationRequiredApi - axios parameter creator
 * @export
 */
var AuthenticationRequiredApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Delete Company
         * @param {string} companyId
         * @param {string} [xApiKey]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompanyCompanyCompanyIdDelete: function (companyId, xApiKey, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'companyId' is not null or undefined
                    (0, common_1.assertParamExists)('deleteCompanyCompanyCompanyIdDelete', 'companyId', companyId);
                    localVarPath = "/company/{company_id}"
                        .replace("{".concat("company_id", "}"), encodeURIComponent(String(companyId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (xApiKey != null) {
                        localVarHeaderParameter['X-Api-Key'] = String(xApiKey);
                    }
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Delete Job Posting
         * @param {string} postingId
         * @param {string} [xApiKey]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJobPostingJobPostingPostingIdDelete: function (postingId, xApiKey, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'postingId' is not null or undefined
                    (0, common_1.assertParamExists)('deleteJobPostingJobPostingPostingIdDelete', 'postingId', postingId);
                    localVarPath = "/job_posting/{posting_id}"
                        .replace("{".concat("posting_id", "}"), encodeURIComponent(String(postingId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (xApiKey != null) {
                        localVarHeaderParameter['X-Api-Key'] = String(xApiKey);
                    }
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Get My Companies
         * @param {string} userId
         * @param {string} [xApiKey]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyCompaniesUserUserIdCompaniesGet: function (userId, xApiKey, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'userId' is not null or undefined
                    (0, common_1.assertParamExists)('getMyCompaniesUserUserIdCompaniesGet', 'userId', userId);
                    localVarPath = "/user/{user_id}/companies"
                        .replace("{".concat("user_id", "}"), encodeURIComponent(String(userId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (xApiKey != null) {
                        localVarHeaderParameter['X-Api-Key'] = String(xApiKey);
                    }
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Get My Postings
         * @param {string} userId
         * @param {string} [xApiKey]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyPostingsUserUserIdPostingsGet: function (userId, xApiKey, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'userId' is not null or undefined
                    (0, common_1.assertParamExists)('getMyPostingsUserUserIdPostingsGet', 'userId', userId);
                    localVarPath = "/user/{user_id}/postings"
                        .replace("{".concat("user_id", "}"), encodeURIComponent(String(userId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (xApiKey != null) {
                        localVarHeaderParameter['X-Api-Key'] = String(xApiKey);
                    }
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Patch Job Posting
         * @param {string} postingId
         * @param {JobPosting} jobPosting
         * @param {string} [xApiKey]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchJobPostingJobPostingPostingIdPatch: function (postingId, jobPosting, xApiKey, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'postingId' is not null or undefined
                    (0, common_1.assertParamExists)('patchJobPostingJobPostingPostingIdPatch', 'postingId', postingId);
                    // verify required parameter 'jobPosting' is not null or undefined
                    (0, common_1.assertParamExists)('patchJobPostingJobPostingPostingIdPatch', 'jobPosting', jobPosting);
                    localVarPath = "/job_posting/{posting_id}"
                        .replace("{".concat("posting_id", "}"), encodeURIComponent(String(postingId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (xApiKey != null) {
                        localVarHeaderParameter['X-Api-Key'] = String(xApiKey);
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(jobPosting, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Post Company
         * @param {Company} company
         * @param {string} [xApiKey]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompanyCompanyPost: function (company, xApiKey, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'company' is not null or undefined
                    (0, common_1.assertParamExists)('postCompanyCompanyPost', 'company', company);
                    localVarPath = "/company";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (xApiKey != null) {
                        localVarHeaderParameter['X-Api-Key'] = String(xApiKey);
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(company, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Post Job Posting
         * @param {JobPosting} jobPosting
         * @param {string} [xApiKey]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postJobPostingJobPostingPost: function (jobPosting, xApiKey, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'jobPosting' is not null or undefined
                    (0, common_1.assertParamExists)('postJobPostingJobPostingPost', 'jobPosting', jobPosting);
                    localVarPath = "/job_posting";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (xApiKey != null) {
                        localVarHeaderParameter['X-Api-Key'] = String(xApiKey);
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(jobPosting, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Put Company
         * @param {string} companyId
         * @param {Company} company
         * @param {string} [xApiKey]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCompanyCompanyCompanyIdPut: function (companyId, company, xApiKey, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'companyId' is not null or undefined
                    (0, common_1.assertParamExists)('putCompanyCompanyCompanyIdPut', 'companyId', companyId);
                    // verify required parameter 'company' is not null or undefined
                    (0, common_1.assertParamExists)('putCompanyCompanyCompanyIdPut', 'company', company);
                    localVarPath = "/company/{company_id}"
                        .replace("{".concat("company_id", "}"), encodeURIComponent(String(companyId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (xApiKey != null) {
                        localVarHeaderParameter['X-Api-Key'] = String(xApiKey);
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(company, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
exports.AuthenticationRequiredApiAxiosParamCreator = AuthenticationRequiredApiAxiosParamCreator;
/**
 * AuthenticationRequiredApi - functional programming interface
 * @export
 */
var AuthenticationRequiredApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.AuthenticationRequiredApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary Delete Company
         * @param {string} companyId
         * @param {string} [xApiKey]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompanyCompanyCompanyIdDelete: function (companyId, xApiKey, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteCompanyCompanyCompanyIdDelete(companyId, xApiKey, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Delete Job Posting
         * @param {string} postingId
         * @param {string} [xApiKey]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJobPostingJobPostingPostingIdDelete: function (postingId, xApiKey, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteJobPostingJobPostingPostingIdDelete(postingId, xApiKey, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get My Companies
         * @param {string} userId
         * @param {string} [xApiKey]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyCompaniesUserUserIdCompaniesGet: function (userId, xApiKey, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getMyCompaniesUserUserIdCompaniesGet(userId, xApiKey, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get My Postings
         * @param {string} userId
         * @param {string} [xApiKey]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyPostingsUserUserIdPostingsGet: function (userId, xApiKey, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getMyPostingsUserUserIdPostingsGet(userId, xApiKey, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Patch Job Posting
         * @param {string} postingId
         * @param {JobPosting} jobPosting
         * @param {string} [xApiKey]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchJobPostingJobPostingPostingIdPatch: function (postingId, jobPosting, xApiKey, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.patchJobPostingJobPostingPostingIdPatch(postingId, jobPosting, xApiKey, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Post Company
         * @param {Company} company
         * @param {string} [xApiKey]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompanyCompanyPost: function (company, xApiKey, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postCompanyCompanyPost(company, xApiKey, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Post Job Posting
         * @param {JobPosting} jobPosting
         * @param {string} [xApiKey]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postJobPostingJobPostingPost: function (jobPosting, xApiKey, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postJobPostingJobPostingPost(jobPosting, xApiKey, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Put Company
         * @param {string} companyId
         * @param {Company} company
         * @param {string} [xApiKey]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCompanyCompanyCompanyIdPut: function (companyId, company, xApiKey, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.putCompanyCompanyCompanyIdPut(companyId, company, xApiKey, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.AuthenticationRequiredApiFp = AuthenticationRequiredApiFp;
/**
 * AuthenticationRequiredApi - factory interface
 * @export
 */
var AuthenticationRequiredApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.AuthenticationRequiredApiFp)(configuration);
    return {
        /**
         *
         * @summary Delete Company
         * @param {string} companyId
         * @param {string} [xApiKey]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompanyCompanyCompanyIdDelete: function (companyId, xApiKey, options) {
            return localVarFp.deleteCompanyCompanyCompanyIdDelete(companyId, xApiKey, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Delete Job Posting
         * @param {string} postingId
         * @param {string} [xApiKey]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJobPostingJobPostingPostingIdDelete: function (postingId, xApiKey, options) {
            return localVarFp.deleteJobPostingJobPostingPostingIdDelete(postingId, xApiKey, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get My Companies
         * @param {string} userId
         * @param {string} [xApiKey]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyCompaniesUserUserIdCompaniesGet: function (userId, xApiKey, options) {
            return localVarFp.getMyCompaniesUserUserIdCompaniesGet(userId, xApiKey, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get My Postings
         * @param {string} userId
         * @param {string} [xApiKey]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyPostingsUserUserIdPostingsGet: function (userId, xApiKey, options) {
            return localVarFp.getMyPostingsUserUserIdPostingsGet(userId, xApiKey, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Patch Job Posting
         * @param {string} postingId
         * @param {JobPosting} jobPosting
         * @param {string} [xApiKey]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchJobPostingJobPostingPostingIdPatch: function (postingId, jobPosting, xApiKey, options) {
            return localVarFp.patchJobPostingJobPostingPostingIdPatch(postingId, jobPosting, xApiKey, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Post Company
         * @param {Company} company
         * @param {string} [xApiKey]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompanyCompanyPost: function (company, xApiKey, options) {
            return localVarFp.postCompanyCompanyPost(company, xApiKey, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Post Job Posting
         * @param {JobPosting} jobPosting
         * @param {string} [xApiKey]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postJobPostingJobPostingPost: function (jobPosting, xApiKey, options) {
            return localVarFp.postJobPostingJobPostingPost(jobPosting, xApiKey, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Put Company
         * @param {string} companyId
         * @param {Company} company
         * @param {string} [xApiKey]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCompanyCompanyCompanyIdPut: function (companyId, company, xApiKey, options) {
            return localVarFp.putCompanyCompanyCompanyIdPut(companyId, company, xApiKey, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.AuthenticationRequiredApiFactory = AuthenticationRequiredApiFactory;
/**
 * AuthenticationRequiredApi - object-oriented interface
 * @export
 * @class AuthenticationRequiredApi
 * @extends {BaseAPI}
 */
var AuthenticationRequiredApi = /** @class */ (function (_super) {
    __extends(AuthenticationRequiredApi, _super);
    function AuthenticationRequiredApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Delete Company
     * @param {string} companyId
     * @param {string} [xApiKey]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationRequiredApi
     */
    AuthenticationRequiredApi.prototype.deleteCompanyCompanyCompanyIdDelete = function (companyId, xApiKey, options) {
        var _this = this;
        return (0, exports.AuthenticationRequiredApiFp)(this.configuration).deleteCompanyCompanyCompanyIdDelete(companyId, xApiKey, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Delete Job Posting
     * @param {string} postingId
     * @param {string} [xApiKey]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationRequiredApi
     */
    AuthenticationRequiredApi.prototype.deleteJobPostingJobPostingPostingIdDelete = function (postingId, xApiKey, options) {
        var _this = this;
        return (0, exports.AuthenticationRequiredApiFp)(this.configuration).deleteJobPostingJobPostingPostingIdDelete(postingId, xApiKey, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get My Companies
     * @param {string} userId
     * @param {string} [xApiKey]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationRequiredApi
     */
    AuthenticationRequiredApi.prototype.getMyCompaniesUserUserIdCompaniesGet = function (userId, xApiKey, options) {
        var _this = this;
        return (0, exports.AuthenticationRequiredApiFp)(this.configuration).getMyCompaniesUserUserIdCompaniesGet(userId, xApiKey, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get My Postings
     * @param {string} userId
     * @param {string} [xApiKey]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationRequiredApi
     */
    AuthenticationRequiredApi.prototype.getMyPostingsUserUserIdPostingsGet = function (userId, xApiKey, options) {
        var _this = this;
        return (0, exports.AuthenticationRequiredApiFp)(this.configuration).getMyPostingsUserUserIdPostingsGet(userId, xApiKey, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Patch Job Posting
     * @param {string} postingId
     * @param {JobPosting} jobPosting
     * @param {string} [xApiKey]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationRequiredApi
     */
    AuthenticationRequiredApi.prototype.patchJobPostingJobPostingPostingIdPatch = function (postingId, jobPosting, xApiKey, options) {
        var _this = this;
        return (0, exports.AuthenticationRequiredApiFp)(this.configuration).patchJobPostingJobPostingPostingIdPatch(postingId, jobPosting, xApiKey, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Post Company
     * @param {Company} company
     * @param {string} [xApiKey]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationRequiredApi
     */
    AuthenticationRequiredApi.prototype.postCompanyCompanyPost = function (company, xApiKey, options) {
        var _this = this;
        return (0, exports.AuthenticationRequiredApiFp)(this.configuration).postCompanyCompanyPost(company, xApiKey, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Post Job Posting
     * @param {JobPosting} jobPosting
     * @param {string} [xApiKey]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationRequiredApi
     */
    AuthenticationRequiredApi.prototype.postJobPostingJobPostingPost = function (jobPosting, xApiKey, options) {
        var _this = this;
        return (0, exports.AuthenticationRequiredApiFp)(this.configuration).postJobPostingJobPostingPost(jobPosting, xApiKey, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Put Company
     * @param {string} companyId
     * @param {Company} company
     * @param {string} [xApiKey]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationRequiredApi
     */
    AuthenticationRequiredApi.prototype.putCompanyCompanyCompanyIdPut = function (companyId, company, xApiKey, options) {
        var _this = this;
        return (0, exports.AuthenticationRequiredApiFp)(this.configuration).putCompanyCompanyCompanyIdPut(companyId, company, xApiKey, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return AuthenticationRequiredApi;
}(base_1.BaseAPI));
exports.AuthenticationRequiredApi = AuthenticationRequiredApi;
/**
 * PublicApi - axios parameter creator
 * @export
 */
var PublicApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Get Companies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesCompaniesGet: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/companies";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Get Company
         * @param {string} companyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyCompanyCompanyIdGet: function (companyId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'companyId' is not null or undefined
                    (0, common_1.assertParamExists)('getCompanyCompanyCompanyIdGet', 'companyId', companyId);
                    localVarPath = "/company/{company_id}"
                        .replace("{".concat("company_id", "}"), encodeURIComponent(String(companyId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Get Company Postings
         * @param {string} companyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyPostingsCompanyCompanyIdPostingsGet: function (companyId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'companyId' is not null or undefined
                    (0, common_1.assertParamExists)('getCompanyPostingsCompanyCompanyIdPostingsGet', 'companyId', companyId);
                    localVarPath = "/company/{company_id}/postings"
                        .replace("{".concat("company_id", "}"), encodeURIComponent(String(companyId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Get Posting
         * @param {string} postingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPostingPostingPostingIdGet: function (postingId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'postingId' is not null or undefined
                    (0, common_1.assertParamExists)('getPostingPostingPostingIdGet', 'postingId', postingId);
                    localVarPath = "/posting/{posting_id}"
                        .replace("{".concat("posting_id", "}"), encodeURIComponent(String(postingId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Get Postings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPostingsPostingsGet: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/postings";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Post Auth Google
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAuthGoogleAuthGooglePost: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/auth/google";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
exports.PublicApiAxiosParamCreator = PublicApiAxiosParamCreator;
/**
 * PublicApi - functional programming interface
 * @export
 */
var PublicApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.PublicApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary Get Companies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesCompaniesGet: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getCompaniesCompaniesGet(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get Company
         * @param {string} companyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyCompanyCompanyIdGet: function (companyId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getCompanyCompanyCompanyIdGet(companyId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get Company Postings
         * @param {string} companyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyPostingsCompanyCompanyIdPostingsGet: function (companyId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getCompanyPostingsCompanyCompanyIdPostingsGet(companyId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get Posting
         * @param {string} postingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPostingPostingPostingIdGet: function (postingId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getPostingPostingPostingIdGet(postingId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get Postings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPostingsPostingsGet: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getPostingsPostingsGet(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Post Auth Google
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAuthGoogleAuthGooglePost: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.postAuthGoogleAuthGooglePost(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.PublicApiFp = PublicApiFp;
/**
 * PublicApi - factory interface
 * @export
 */
var PublicApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.PublicApiFp)(configuration);
    return {
        /**
         *
         * @summary Get Companies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesCompaniesGet: function (options) {
            return localVarFp.getCompaniesCompaniesGet(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get Company
         * @param {string} companyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyCompanyCompanyIdGet: function (companyId, options) {
            return localVarFp.getCompanyCompanyCompanyIdGet(companyId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get Company Postings
         * @param {string} companyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyPostingsCompanyCompanyIdPostingsGet: function (companyId, options) {
            return localVarFp.getCompanyPostingsCompanyCompanyIdPostingsGet(companyId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get Posting
         * @param {string} postingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPostingPostingPostingIdGet: function (postingId, options) {
            return localVarFp.getPostingPostingPostingIdGet(postingId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get Postings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPostingsPostingsGet: function (options) {
            return localVarFp.getPostingsPostingsGet(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Post Auth Google
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAuthGoogleAuthGooglePost: function (options) {
            return localVarFp.postAuthGoogleAuthGooglePost(options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.PublicApiFactory = PublicApiFactory;
/**
 * PublicApi - object-oriented interface
 * @export
 * @class PublicApi
 * @extends {BaseAPI}
 */
var PublicApi = /** @class */ (function (_super) {
    __extends(PublicApi, _super);
    function PublicApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Get Companies
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    PublicApi.prototype.getCompaniesCompaniesGet = function (options) {
        var _this = this;
        return (0, exports.PublicApiFp)(this.configuration).getCompaniesCompaniesGet(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get Company
     * @param {string} companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    PublicApi.prototype.getCompanyCompanyCompanyIdGet = function (companyId, options) {
        var _this = this;
        return (0, exports.PublicApiFp)(this.configuration).getCompanyCompanyCompanyIdGet(companyId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get Company Postings
     * @param {string} companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    PublicApi.prototype.getCompanyPostingsCompanyCompanyIdPostingsGet = function (companyId, options) {
        var _this = this;
        return (0, exports.PublicApiFp)(this.configuration).getCompanyPostingsCompanyCompanyIdPostingsGet(companyId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get Posting
     * @param {string} postingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    PublicApi.prototype.getPostingPostingPostingIdGet = function (postingId, options) {
        var _this = this;
        return (0, exports.PublicApiFp)(this.configuration).getPostingPostingPostingIdGet(postingId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get Postings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    PublicApi.prototype.getPostingsPostingsGet = function (options) {
        var _this = this;
        return (0, exports.PublicApiFp)(this.configuration).getPostingsPostingsGet(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Post Auth Google
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    PublicApi.prototype.postAuthGoogleAuthGooglePost = function (options) {
        var _this = this;
        return (0, exports.PublicApiFp)(this.configuration).postAuthGoogleAuthGooglePost(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return PublicApi;
}(base_1.BaseAPI));
exports.PublicApi = PublicApi;
